import React from "react";
import "../scss/molecules/_content-component.scss";

const DataPolicy = ({ email, company }) => (
  <section className="container content-component">
    <h1>Privacy beleid</h1>
    <h2>Het belang van gegevensbescherming</h2>
    <p>
      {company} hecht grote waarde aan het correct beschermen van de gegevens
      die zij verwerkt, in het bijzonder persoonsgegevens. Aan de hand van deze
      policy wil {company} op strategisch niveau vastleggen op welke wijze
      gegevens beschermd worden, welke verantwoordelijkheden hierrond zijn
      toegewezen en welke prioriteiten {company} heeft bepaald rond de
      bescherming van gegevens.
    </p>
    <p>
      In het bijzonder wil {company} de gegevens van gebruikers van de website
      en de persoonsgegevens die zij ter beschikking stellen, beschermen tegen:
    </p>
    <ul>
      <li>verlies: gegevens zijn niet meer beschikbaar;</li>
      <li>lekken: gegevens komen in verkeerde handen terecht;</li>
      <li>
        fouten: gegevens zijn niet correct, bijvoorbeeld verouderd of
        onvolledig; niet toegankelijk: wanneer nodig zijn de gegevens niet
        toegankelijk;
      </li>
      <li>
        onterecht inkijken: ingekeken door personen die hiertoe niet gemachtigd
        zijn;
      </li>
      <li>
        het niet kunnen nagaan wie de gegevens inkeek, wijzigde of verwijderde;
      </li>
      <li>
        verwerkingen die niet in lijn liggen met regelgeving, richtlijnen en
        normen.
      </li>
    </ul>
    <p>
      {company} wil in deze verklaring een beroep doen op iedereen die betrokken
      is bij de elektronische en papieren verwerking om samen, vanuit een
      gemeenschappelijke visie én vanuit onze gezamenlijke wil om
      kwaliteitsvolle dienstverlening aan te bieden, de verwerking van
      persoonsgegevens van onze bezoekers correct te laten verlopen.
    </p>
    <h2>Scope van het gegevensbeschermingsbeleid</h2>
    <p>
      Deze policy is van toepassing voor de gehele levensduur van informatie
      binnen de website van Attorno, van het verkrijgen van informatie tot de
      uiteindelijke verwijdering van informatie binnen de organisatie.
    </p>
    <p>Dit beleid geldt voor heel Attorno:</p>
    <ul>
      <li>Het kantoor van Attorno;</li>
      <li>
        Alle personeelsleden van Attorno, zowel interne medewerkers als externen
        die tewerkgesteld zijn binnen {company} voor bepaalde of onbepaalde
        duur;
      </li>
      <li>
        Alle bedrijfsmiddelen en informatieverwerkende systemen beheerd door
        {company} evenals systemen beheerd door externen ten behoeve van
        informatieverwerkingen voor {company} zoals databases, informatie
        ongeacht de drager ervan, netwerken, datacenters, etc;
      </li>
      <li>
        Alle verwerkingsactiviteiten, zowel als verwerkingsverantwoordelijke als
        verwerker.
      </li>
    </ul>
    <p>
      Voor bepaalde domeinen of processen binnen {company} kunnen aanvullende
      richtlijnen of procedures worden uitgewerkt die in detail beschrijven
      welke maatregelen genomen worden om het gewenste niveau van
      gegevensbescherming te bereiken.
    </p>
    <p>
      Gezien de belangrijke rol van de ICT-leveranciers bij het opzetten van de
      ICT-omgeving om gegevens te verwerken, legt de privacy policy hiervoor ook
      de beleidsprincipes vast.
    </p>
    <h2>Beleidsdoelstellingen voor gegevensbescherming</h2>
    <p>
      Attorno, zowel in haar rol als verwerkingsverantwoordelijke als verwerker:
    </p>
    <ul>
      <li>
        Is transparant over de persoonsgegevens die het verwerkt en het
        verwerkingsdoel, zowel naar de betrokkene, de klanten als naar de
        toezichthouders. De gevoerde communicatie is eerlijk, eenvoudig
        toegankelijk en begrijpelijk. Het transparantieprincipe is ook van
        toepassing wanneer de persoonsgegevens worden uitgewisseld.
      </li>
      <li>
        Verwerkt enkel de gegevens die relevant zijn voor het uitvoeren van haar
        taken. Elke taak waarbij persoonsgegevens worden verwerkt, is
        rechtmatig. Dit wordt telkens geëvalueerd bij een nieuw verwerkingsdoel,
        waar nodig aan de hand van een gegevensbeschermingseffectbeoordeling.
      </li>
      <li>
        Verwerkt enkel de persoonsgegevens die strikt noodzakelijk zijn voor de
        uitvoering van de activiteiten. Zo worden identificatoren die horen bij
        de persoonsgegevens tot een minimum herleid.
      </li>
      <li>
        Kijkt toe op de integriteit van de persoonsgegevens gedurende de ganse
        verwerkingscyclus.
      </li>
      <li>
        Bewaart gegevens niet langer dan noodzakelijk. De noodzakelijkheid is
        afgetoetst tegenover wettelijke verplichtingen en de rechten en
        vrijheden van de betrokkene.
      </li>
      <li>
        Voorkomt inbreuken die voortvloeien uit het verwerken van
        persoonsgegevens. Informatieveiligheid, gegevensbescherming bij ontwerp
        en privacy-vriendelijke standaardinstellingen zijn hiervoor
        hulpmiddelen. Wanneer een inbreuk plaatsvindt, wordt hierover
        gerapporteerd in lijn met de regelgeving ter zake.
      </li>
      <li>
        Is in staat om alle geldende rechten van een betrokkene, zoals het recht
        op inzage, afschrift en eventueel ook schrapping uit te voeren.
      </li>
      <li>
        Waakt er actief over dat bij het verwerken van de persoonsgegevens voor
        een welbepaald doel, de rechten en vrijheden van de betrokkene
        gevrijwaard blijven.
      </li>
      <li>
        Verwerkt gegevens in lijn met de rechten en vrijheden die gelden in de
        Europese Economische Ruimte en controleert de toepassing hiervan wanneer
        de gegevens worden uitgewisseld daarbuiten.
      </li>
      <li>
        Kan aantonen dat het alle beleidsdoelstellingen naleeft, conform de
        wettelijke bepalingen. Deze verantwoordingsplicht wordt bewaakt door
        interne toezicht en controle en is uitvoerbaar volgens de wettelijk
        geldende principes.
      </li>
    </ul>
    <h2>Welke persoonsgegevens verzamelen we?</h2>
    <p>We verzamelen verschillende soorten persoonsgegevens:</p>
    <ul>
      <li>IP adres;</li>
      <li>Browser-informatie;</li>
      <li>De externe webpagina vanwaar je komt;</li>
      <li>De pagina’s die je bezocht hebt op onze website;</li>
      <li>Tijdstip en duur van de bezochte pagina’s.</li>
    </ul>
    <h2>Waarvoor gebruiken we de persoonsgegevens?</h2>
    <p>
      We gebruiken de persoonsgegevens die we verzamelen om de juiste service te
      kunnen leveren, te kunnen onderhouden, beschermen en te verbeteren. Jouw
      persoonlijke gegevens worden enkel bijgehouden zolang nodig voor het doel
      waarvoor we ze verzameld hebben.
    </p>
    <p>We kunnen je persoonsgegevens gebruiken voor:</p>
    <ul>
      <li>
        Communicatie met jou, voornamelijk om feedback te geven wanneer er een
        vraag is gesteld via het contactformulier of wanneer je gesolliciteerd
        hebt via onze site;
      </li>
      <li>We delen jouw persoonsgegevens enkel en alleen:</li>
      <li>
        Met jouw toestemming: we delen jouw persoonsgegevens enkel en alleen met
        andere bedrijven dan Attorno, mits we jouw toestemming hiervoor hebben.
        Wees ervan bewust dat wanneer je klikt op een social media knop, de
        privacy policy van deze aanbieder geldt.
      </li>
      <li>
        Voor wettelijke redenen: het kan zijn dat we jouw persoonsgegevens
        moeten delen om in orde te zijn met een wettelijke verplichting;
      </li>
      <li>
        Met partijen die voor ons persoonsgegevens verwerken: deze partijen
        mogen enkel verwerkingen met persoonsgegevens doen in opdracht van
        Attorno. Deze verwerkingen worden vastgelegd in een
        verwerkingsovereenkomst tussen {company} en deze partij.
      </li>
    </ul>
    <h2>Jouw rechten</h2>
    <p>Je mag ons (gratis) vragen:</p>
    <ul>
      <li>Welke data we hebben over jou;</li>
      <li>Wat de bedoeling is van een bepaalde verwerking;</li>
      <li>Wie jouw persoonlijke data allemaal verwerkt.</li>
    </ul>
    <p>Je hebt (gratis) het recht:</p>
    <ul>
      <li>
        Op een kopie van jouw persoonsgegevens die we voor een bepaald doel
        verwerken;
      </li>
      <li>Om een aanvraag in te dienen om jouw data te verwijderen;</li>
      <li>
        Om ons te vragen je persoonlijke data niet meer te verwerken (indien je
        een gegronde en legale reden hebt hiervoor).
      </li>
    </ul>
    <p>
      {company} zal in geen enkel geval persoonsgegevens verwerken die te maken
      hebben met politieke of religieuze voorkeuren of overtuigingen, ras, of
      eender welke data die te maken hebben met je gezondheid of seksleven.
    </p>
    <h2>Contactinformatie</h2>
    <p>
      Wanneer je bijkomende vragen hebt over de verwerking van persoonsgegevens,
      of indien je een verzoek wil indienen om je gegevens aan te passen, in te
      kijken of te verwijderen, kan je ons een email sturen via
      <a href={"mailto:" + email}>{email}</a>. We vragen een bewijs van
      identiteit en verwerken je verzoek binnen de 30 dagen na ontvangst van de
      mail.
    </p>
    <h1>Cookiebeleid</h1>
    <p>
      Onze website maakt gebruik van verschillende soorten cookies. Wij vinden
      het belangrijk dat je zelf controle hebt over jouw online privacy. Daarom
      willen we je graag zo compleet mogelijk informeren over welke cookies we
      gebruiken en voor welke doeleinden.
    </p>
    <h2>Wat zijn cookies?</h2>
    <p>
      Cookies zijn kleine tekstbestanden die een website op je computer, tablet
      of mobiele telefoon plaatst op het moment dat je de website bezoekt. In
      deze cookies wordt informatie over jouw websitebezoek opgeslagen. Met het
      plaatsen van cookies worden persoonsgegevens verwerkt.
    </p>
    <p>
      Door het gebruik van cookies kan een website je herkennen bij een volgend
      bezoek aan de website. Dat kan bijvoorbeeld handig zijn wanneer je niet
      telkens opnieuw wilt inloggen bij een nieuw bezoek aan de website.
    </p>
    <h2>Verschillende soorten cookies</h2>
    <p>
      We maken onderscheid in functionele en niet-functionele cookies.
      Functionele cookies plaatsen we altijd. Deze zijn noodzakelijk om de
      website goed te laten werken. Ze zorgen er bijvoorbeeld voor dat je
      ingelogd kunt blijven op de website wanneer je dat wenst.
    </p>
    <p>
      Naast functionele cookies plaatsen we ook standaard analytische cookies.
      Dit is een voorbeeld van een niet-functionele cookie. Hij is namelijk niet
      nodig voor het goed laten functioneren van de website. Hierdoor weten we
      welke website onderdelen populair zijn en op welke plekken we onze website
      kunnen verbeteren.
    </p>
    <p>
      Pas wanneer je akkoord gaat met het plaatsen van cookies, plaatsen we ook
      een aantal andere niet-functionele cookies. Deze hebben allemaal
      verschillende doeleinden, maar stellen ons in staat om onze
      dienstverlening te verbeteren.
    </p>
    <p>
      Zo gebruiken we cookies om op onze website online surveys te tonen,
      verbeteringen op onze website te testen, social media buttons te tonen of
      om relevante advertenties voor onze diensten te kunnen tonen op andere
      websites.
    </p>
    <h2>Functionele cookies</h2>
    <p>
      Functionele cookies zijn noodzakelijk om de website goed te laten
      functioneren. Deze cookies worden dan ook standaard geplaatst en worden
      niet verwijderd wanneer je de cookies niet accepteert. Functionele cookies
      zorgen ervoor dat je ingelogd kunt blijven tijdens je bezoek aan de
      website. Ook zorgen ze ervoor dat producten in je winkelmandje bewaard
      blijven wanneer je iets wilt kopen in onze webshop.
    </p>
    <h2>Cookies om bezoek te meten</h2>
    <p>
      Analytische cookies stellen ons in staat om het websitebezoek te meten.
      Deze statistieken geven ons inzicht in hoe vaak onze website wordt
      gebruikt, naar welke informatie bezoekers op zoek zijn en welke pagina's
      het meest worden bezocht. Hierdoor weten we welke website onderdelen
      populair zijn en op welke plekken we onze website kunnen verbeteren.
    </p>
    <p>
      We analyseren en verbeteren de website continu om de ervaring voor
      bezoekers zo prettig mogelijk te maken. De statistieken die we verzamelen
      kunnen we niet herleiden naar personen. Deze gegevens worden dus altijd
      anoniem gebruikt.
    </p>
    <p>
      We maken gebruik van Google Analytics om inzichten te verkrijgen uit de
      data. De analytische cookies plaatsen we altijd bij een bezoek aan onze
      website. Deze gegevens worden 26 maanden bijgehouden. Voor meer informatie
      over Google Analytics cookies, zie
      <a
        href="https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage"
        data-new-window="true"
        target="_blank"
        rel="noopener noreferrer"
      >
        hier
      </a>
      .
    </p>
    <h2>Cookies blokkeren en verwijderen</h2>
    <p>
      Wil je cookies van onze website niet geplaatst hebben, dan kun je daarvoor
      kiezen in de cookiemelding die je te zien krijgt bij het eerste bezoek aan
      onze website. Heb je onze cookies eerder geaccepteerd, dan krijg je deze
      melding niet meer te zien en dien je eerst onze cookies te verwijderen.
    </p>
    <p>
      Je kunt er ook voor kiezen om via je browser het plaatsen van cookies te
      blokkeren. Blokkeer je alle cookies, dan zouden websites minder goed
      kunnen werken. Het is beter om alleen de ongewenste cookies selectief uit
      te schakelen. Dit kun je doen in je browser bij de instellingen. Hiermee
      blokkeer je ook meteen de cookies van andere websites die je bezoekt.
    </p>
    <p>Meer informatie per browser kan u hier vinden:</p>
    <ul>
      <li>
        <a
          href="https://support.apple.com/kb/ph19214?locale=en_US"
          data-new-window="true"
          target="_blank"
          rel="noopener noreferrer"
        >
          Safari
        </a>
      </li>
      <li>
        <a
          href="https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DDesktop&amp;hl=en"
          data-new-window="true"
          target="_blank"
          rel="noopener noreferrer"
        >
          Google Chrome
        </a>
      </li>
      <li>
        <a
          href="https://support.mozilla.org/en-US/kb/cookies-information-websites-store-on-your-computer"
          data-new-window="true"
          target="_blank"
          rel="noopener noreferrer"
        >
          Mozilla Firefox
        </a>
      </li>
      <li>
        <a
          href="https://support.microsoft.com/en-us/help/260971/description-of-cookies"
          data-new-window="true"
          target="_blank"
          rel="noopener noreferrer"
        >
          Internet Explorer
        </a>
      </li>
    </ul>
    <p>
      Voor andere browsers, gelieve de documentatie na te lezen van de provider
      zelf.
    </p>
  </section>
);

export default DataPolicy;
