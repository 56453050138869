import React from "react";

import Layout from "../components/layout";
import Nav from "../components/nav";
import Footer from "../components/footer";
import Slogan from "../components/slogan";
import SEO from "../components/seo";
import DataPolicy from "../components/data-policy";
import "../scss/attorno.scss";

const GegevensBescherming = () => (
  <Layout>
    <SEO
      title="Contacteer ons"
      description="Had u toch nog vragen of wil u een offerte ontvangen? Contacteer ons nu vrijblijvend voor een voorstel."
    />

    <Nav />

    <header
      className="intro-block py-3 cover-bg"
      style={{ backgroundImage: "url(/design/img/header/pizza.jpg)" }}
    >
      <div className="container">
        <Slogan heading="Gegevenvsbescherming" />
      </div>
    </header>

    <DataPolicy email="info@attorno.be" company="Attorno" />

    <Footer />
  </Layout>
);

export default GegevensBescherming;
